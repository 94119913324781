


































































import Vue from 'vue';
const Ripple = require('vue-ripple-directive');
import { mapActions, mapState } from 'vuex';
import axios from 'axios';
import { Monitor } from '../store/monitors/types';

interface EditMonitor extends Monitor {
  dirty?: boolean;
  locationStr?: string;
}

export default Vue.extend({
  components: {},
  directives: {
    Ripple,
  },
  data() {
    const columns = [
      {
        key: 'operation',
        label: '',
      },
      {
        key: '_id',
        label: '代碼',
      },
      {
        key: 'desc',
        label: '名稱',
      },
      {
        key: 'monitorTypes',
        label: '測項',
      },
      {
        key: 'locationStr',
        label: '位置',
      },
    ];

    const editMonitors = new Array<EditMonitor>();
    return {
      display: false,
      columns,
      editMonitors,
    };
  },
  computed: {
    ...mapState('monitors', ['monitors']),
    ...mapState('monitorTypes', ['monitorTypes']),
  },
  async mounted() {
    await this.fetchMonitors();
    await this.fetchMonitorTypes();
    this.copyMonitor();
  },
  methods: {
    ...mapActions('monitors', ['fetchMonitors']),
    ...mapActions('monitorTypes', ['fetchMonitorTypes']),
    save() {
      const all = Array<any>();
      for (const m of this.editMonitors) {
        if (m.dirty) {
          if (m.locationStr) {
            try {
              m.location = m.locationStr.split(',').map(t => parseFloat(t));
            } catch (err) {}
          }
          all.push(axios.put(`/Monitor/${m._id}`, m));
        }
      }

      Promise.all(all).then(() => {
        this.fetchMonitors();
        this.copyMonitor();
        this.$bvModal.msgBoxOk('成功');
      });
    },
    copyMonitor() {
      this.editMonitors = new Array<EditMonitor>();
      for (const m of this.monitors) {
        let monitor = m as EditMonitor;
        monitor.dirty = false;
        monitor.locationStr = monitor.location
          ? monitor.location.join(',')
          : '';
        this.editMonitors.push(monitor);
      }
    },
    rollback() {
      this.fetchMonitors();
      this.copyMonitor();
    },
    checkLocation(posStr: string | undefined): boolean {
      try {
        if (posStr === undefined) return true;

        let pos = posStr.split(',').map(t => parseFloat(t));

        if (pos.length === 2 && pos.every(l => !isNaN(l))) return true;
        else {
          this.$bvModal.msgBoxOk(`${posStr}不是有效的位置!`);
          return false;
        }
      } catch (err) {
        this.$bvModal.msgBoxOk(`${posStr}不是有效的位置!`);
        return false;
      }
    },
    async deleteMonitor(row: any) {
      const confirm = await this.$bvModal.msgBoxConfirm(
        `確定要刪除${row.item._id}?`,
        { okTitle: '確認', cancelTitle: '取消' },
      );

      if (!confirm) return;

      const _id = row.item._id;
      const res = await axios.delete(`/Monitor/${_id}`);
      if (res.status == 200) this.$bvModal.msgBoxOk('成功');
      this.fetchMonitors();
    },
    markDirty(item: any) {
      item.dirty = true;
    },
  },
});
